<template>
    <v-dialog
        v-model="confirmationDialog"
        :maxWidth="maxWidth"
        v-bind="$attrs"
    >
        <v-card class="confirmation-modal">
            <v-card-title :class="`confirmation-modal__title ${headerClass}`">
                <slot name="confirmationTitle">
                    <span>
                        {{ confirmationTitle }}
                    </span>
                </slot>
                <slot name="confirmationTitleCloseIcon">
                    <span>
                        <v-btn
                            v-if="!hideCancelButton"
                            aria-label="Close"
                            class="confirmation-modal__close-button"
                            icon
                            text
                            color="white"
                            @click="confirmationDialog = false"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </span>
                </slot>
            </v-card-title>
            <v-card-text class="confirmation-modal__text">
                <slot name="confirmationText">
                    <span>
                        {{ confirmationText }}
                    </span>
                </slot>
            </v-card-text>
            <v-card-actions class="confirmation-modal__buttons actions">
                <slot name="confirmationActions">
                    <span>
                        <ClButton
                            v-if="!hideCancelButton"
                            class="white--text actions__cancel"
                            :color="cancelButtonColor"
                            :rounded="false"
                            type="secondary"
                            @click="confirmationDialog = false"
                        >
                            Cancel
                        </ClButton>
                        <ClButton
                            :color="primaryButtonColor"
                            :rounded="false"
                            @click="confirm()"
                        >
                            {{ primaryButtonText }}
                        </ClButton>
                    </span>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'ModalConfirmation',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        confirmationTitle: {
            type: String,
            required: false,
            default: '',
        },
        confirmationText: {
            type: String,
            required: false,
            default: '',
        },
        primaryButtonText: {
            type: String,
            required: false,
            default: 'Ok',
        },
        primaryButtonColor: {
            type: String,
            required: false,
            default: 'primary',
        },
        maxWidth: {
            type: String,
            required: false,
            default: '400',
        },
        hideCancelButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        cancelButtonColor: {
            type: String,
            required: false,
            default: 'primary',
        },
        headerClass: {
            type: String,
            default: 'warning',
            required: false,
        },
    },
    computed: {
        confirmationDialog: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
    methods: {
        confirm() {
            this.$emit( 'confirm' );
        },
    },

};
</script>
<style lang="scss">
@import '@/assets/sass/variables.scss';
#app .confirmation-modal {
    &__title {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        color:$white;
    }
    &__text {
        padding: 1rem;
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        padding-top: 0;
    }
}

#app .actions__cancel {
    margin-right: 0.5rem;
}
</style>
