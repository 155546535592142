<template>
    <div class="sidebar-thin rra-ean-detail custom-container">
        <TheSidebar :fullSize="false"/>

        <ThePageHeadingBar
            :pageHeading="( rraDetailInfo.number || '' )"
            fullSize
        />

        <section class="main">
            <ModalConfirmation
                v-model="shownInvalidInputModal"
                confirmationText="Unable to save. A Quantity to Return is greater than its Quantity Remaining."
                confirmationTitle="Invalid Quantity to Return"
                hideCancelButton
                maxWidth="300px"
                persistent
                primaryButtonColor="warning"
                @confirm="shownInvalidInputModal = false"
            />

            <div class="summary-section">
                <div class="summary-section__column--1 summary-section__label">
                    ISBN
                </div>
                <div class="summary-section__column--1 summary-section__data">
                    {{ ean }}
                </div>
                <div class="summary-section__column--1 summary-section__label">
                    Title Description
                </div>
                <div class="summary-section__column--1 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ eanDetails.title }}</span>
                </div>
                <div class="summary-section__column--1 summary-section__label">
                    Condition
                </div>
                <div class="summary-section__column--1 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ eanDetails.condition }}</span>
                </div>

                <div class="summary-section__column--2 summary-section__label">
                    Inventory this Location
                </div>
                <div class="summary-section__column--2 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ eanDetails.quantityThisLocation }}</span>
                </div>
                <div class="summary-section__column--2 summary-section__label">
                    Inventory All Locations
                </div>
                <div class="summary-section__column--2 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ eanDetails.quantityAllLocations }}</span>
                </div>
                <div class="summary-section__column--3 summary-section__label">
                    Location
                </div>
                <div class="summary-section__column--3 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ rraDetailInfo.location.name }}</span>
                </div>
                <div class="summary-section__column--3 summary-section__label">
                    Supplier
                </div>
                <div class="summary-section__column--3 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="text"/>
                    <span v-else>{{ rraDetailInfo.supplier.name }}</span>
                </div>
                <div class="summary-section__column--3 summary-section__label">
                    Title Designators
                </div>
                <div class="summary-section__column--3 summary-section__data">
                    <v-skeleton-loader v-if="inFlight" type="paragraph"/>
                    <div
                        v-else
                        class="title-designators__chips"
                    >
                        <div
                            v-for="( designator, index ) in eanDetails.titleDesignators"
                            :key="index"
                        >
                            <v-chip
                                class="title-designators__item"
                                disabled
                                label
                                outlined
                            >
                                {{ designator }}
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
            <v-expansion-panels
                v-model="panels"
                class="expansion-panels"
                multiple
            >
                <v-expansion-panel class="open-to-buy">
                    <v-expansion-panel-header>
                        <h2>Open to Buy</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <TableOpenToBuy
                            :items="tableOpenToBuy.items"
                            :loading="tableOpenToBuy.loading"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="returnable-items">
                    <v-expansion-panel-header>
                        <h2>Returnable Items</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <TableBaseFilterable
                            :filter.sync="tableReturnableItems.filter"
                            :headers="tableReturnableItems.headers"
                            :items="eanDetailItems"
                            :loading="inFlight"
                            :noDataText="( inFlight ? 'Searching...' : 'No Returnable Items found' )"
                            :sortBy="[ 'daysRemaining' ]"
                            :sortDesc="[ false ]"
                            hideAdd
                        >
                            <template #[`item.supplierDocumentNumber`]="{ item }">
                                {{ item.supplierDocumentNumber || 'Not Set' }}
                            </template>
                            <template #[`item.invoiceDate`]="{ item }">
                                {{ item.invoiceDate ? FormatUtil.epochToUTCString(item.invoiceDate) : 'Not Set' }}
                            </template>
                            <template #[`item.purchaseOrderNumber`]="{ item }">
                                {{ item.purchaseOrderNumber || 'Not Set' }}
                            </template>
                            <template #[`item.purchaseOrderDate`]="{ item }">
                                {{ item.purchaseOrderDate ? FormatUtil.epochToUTCString(item.purchaseOrderDate) : 'Not Set' }}
                            </template>
                            <template #[`item.returnDeadline`]="{ item }">
                                {{ item.returnDeadline ? FormatUtil.epochToUTCString(item.returnDeadline) : 'Not Set' }}
                            </template>
                            <template #[`item.quantityToReturn`]="{ item }">
                                <ClInput
                                    :disabled="(
                                        inFlight
                                        || ( item.quantityRemaining === 0 )
                                        || !item.invoiceItemId
                                        || !item.invoiceId
                                        || !item.purchaseOrderId
                                    )"
                                    :rules="[ isValidQuantityToReturn( item ) ]"
                                    :value="item.quantityToReturn.toString()"
                                    class="returnable-items__qty-to-return"
                                    type="number"
                                    @input="( val ) => updateQuantityToReturn( item, val )"
                                />
                            </template>
                            <template #[`item.extendedCost`]="{ item }">
                                {{ FormatUtil.toCurrency(item.extendedCost) }}
                            </template>
                            <template #[`item.cost`]="{ item }">
                                {{ FormatUtil.toCurrency(item.cost) }}
                            </template>
                            <template #[`item.actions`]="{ item }">
                                <ModalRMANumber
                                    :reason="item.reason"
                                    :rmaNumber="item.rma"
                                    @update="( payload ) => updateRMAAndReason( { item, ...payload } )"
                                    @activator:click="open => open()"
                                >
                                    <template v-slot="{ on, attrs }">
                                        <ClButton
                                            :disabled="inFlight || ( item.quantityToReturn === 0 )"
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </ClButton>
                                    </template>
                                </ModalRMANumber>
                            </template>
                        </TableBaseFilterable>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </section>

        <ThePageFooterBar>
            <div class="footer">
                <ClButton
                    :disabled="inFlight"
                    :rounded="false"
                    class="footer__cancel"
                    @click="cancelButtonHandler()"
                >
                    Cancel
                </ClButton>
                <ClButton
                    :disabled="inFlight"
                    :rounded="false"
                    class="footer__save"
                    @click="saveButtonHandler( true )"
                >
                    Save
                </ClButton>
                <ClButton
                    :disabled="inFlight"
                    :rounded="false"
                    class="footer__save"
                    @click="saveAndNextButtonHandler()"
                >
                    Save and Next
                </ClButton>
            </div>
        </ThePageFooterBar>
    </div>
</template>
<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import TableOpenToBuy from '@/components/tables/TableOpenToBuy';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import ModalConfirmation from '@/components/modals/ModalConfirmation';
import ModalRMANumber from '@/views/RRA/common/ModalRMANumber';

export default {
    name: 'RRAEANDetail',
    components: {
        ThePageHeadingBar,
        TheSidebar,
        ThePageFooterBar,
        TableOpenToBuy,
        TableBaseFilterable,
        ModalConfirmation,
        ModalRMANumber,
    },
    data() {
        return {
            FormatUtil,

            ean: '',
            condition: '',

            shownInvalidInputModal: false,

            panels: [ 2 ], // auto expand specified panels
            tableReturnableItems: {
                filter: '',
                headers: [
                    {
                        text: 'Invoice Number',
                        value: 'supplierDocumentNumber',
                    },
                    {
                        text: 'Invoice Date',
                        value: 'invoiceDate',
                    },
                    {
                        text: 'PO Number',
                        value: 'purchaseOrderNumber',
                    },
                    {
                        text: 'PO Date',
                        value: 'purchaseOrderDate',
                    },
                    {
                        text: 'Return Deadline',
                        value: 'returnDeadline',
                    },
                    {
                        text: 'Days Remaining',
                        value: 'daysRemaining',
                        align: 'end',
                    },
                    {
                        text: 'Qty Invoice/Received',
                        value: 'quantityInvoiced',
                        align: 'end',
                    },
                    {
                        text: 'Qty Previously Returned',
                        value: 'quantityReturned',
                        align: 'end',
                    },
                    {
                        text: 'Qty Remaining',
                        value: 'quantityRemaining',
                        align: 'end',
                    },
                    {
                        text: 'Qty to Return',
                        value: 'quantityToReturn',
                        align: 'end',
                    },
                    {
                        text: 'PO/Invoice Cost',
                        value: 'cost',
                        align: 'end',
                    },
                    {
                        text: 'Ext Cost',
                        value: 'extendedCost',
                        align: 'end',
                    },
                    {
                        text: 'Edit RMA',
                        value: 'actions',
                        align: 'end',
                    },
                ],
            },
            tableOpenToBuy: {
                loading: false,
                items: [],
            },
        };
    },
    computed: {
        ...mapGetters( {
            hasInvalidQuantityToReturn: 'RRA/Detail/EANDetail/hasInvalidQuantityToReturn',
            hasRRADetailInfo: 'RRA/Detail/hasInfo',
            indexOfCurrentItem: 'RRA/Detail/EANDetail/indexOfCurrentItem',
            canGoToNext: 'RRA/Detail/EANDetail/canGoToNext',
        } ),
        ...mapState( {
            rraId: ( state ) => state.RRA.Detail.info.id,
            rraDetailInfo: ( state ) => state.RRA.Detail.info,
            filteredRRADetailItems: ( state ) => state.RRA.Detail.tableRRALines.items,
            inFlight: ( state ) => state.RRA.Detail.EANDetail.inFlight,
        } ),
        eanDetails: {
            get() {
                return this.$store.state.RRA.Detail.EANDetail.info;
            },
        },
        eanDetailItems: {
            get() {
                return this.eanDetails.detailItems ?? [];
            },
        },
    },
    watch: {
        $route: {
            handler: 'init',
            immediate: true,
        },
    },
    beforeDestroy() {
        this.$store.dispatch( 'RRA/Detail/EANDetail/reset' );
    },
    methods: {
        async init() {
            // Grab locations, and title designators
            this.$store.dispatch( 'GlobalResources/init' );

            // Provide a default fallback condition of 'NEW'
            this.condition = this.$route.params.condition ?? 'NEW';
            this.ean = this.$route.params.ean;

            if ( !this.hasRRADetailInfo ) {
                try {
                    await this.getRRADetails();
                } catch ( e ) {
                    // Route back to search if unable to get RRADetails
                    this.$router.push( { path: '/rra' } );
                    this.$store.dispatch( 'setErrorNotification', `${ e.response.data.message || e.message }`, { root: true } );
                }
            }

            try {
                // Grab EANDetails and all OTB info at once
                await Promise.all( [
                    this.getEanDetails(),
                    this.getOpenToBuyInfo( 'tableOpenToBuy', false ),
                ] );
            } catch ( e ) {
                // Route back to RRADetail if unable to get EANDetails
                this.$router.push( {
                    name: 'RRADetail',
                    params: { id: `${ this.$route.params.id }` },
                } );
                this.$store.dispatch( 'setErrorNotification', `${ e.message }`, { root: true } );
            }
        },

        async getRRADetails() {
            await this.$store.dispatch( 'RRA/Detail/get', {
                id: this.$route.params.id,
            } );
        },
        async getEanDetails() {
            await this.$store.dispatch( 'RRA/Detail/EANDetail/get', {
                ean: this.ean,
                condition: this.condition,
                rraId: this.$route.params.id,
            } );
        },
        async getOpenToBuyInfo( associatedTableAttribute, allowSubscription ) {
            this[ associatedTableAttribute ].loading = true;
            try {
                this[ associatedTableAttribute ].items = await this.$store.dispatch( 'RRA/Detail/EANDetail/getOpenToBuyInfo', {
                    productCode: this.ean,
                    allowSubscription,
                } );
            } finally {
                this[ associatedTableAttribute ].loading = false;
            }
        },

        updateQuantityToReturn( item, quantityToReturn ) {
            const intQuantityToReturn = Number.parseInt( quantityToReturn, 10 );
            if ( Number.isNaN( intQuantityToReturn ) ) {
                return;
            }
            this.$store.dispatch( 'RRA/Detail/EANDetail/modifyEanItem', {
                eanDetailItem: {
                    ...item,
                    quantityToReturn: intQuantityToReturn,
                },
            } );
        },
        async updateRMAAndReason( { item, rmaNumber, reason } ) {
            await this.$store.dispatch( 'RRA/Detail/EANDetail/modifyEanItem', {
                eanDetailItem: {
                    ...item,
                    reason,
                    rma: rmaNumber,
                },
            } );
            await this.saveButtonHandler();
        },

        async goToRRADetail() {
            await this.$router.push( {
                name: 'RRADetail',
                params: { id: `${ this.rraId }` },
            } );
        },
        cancelButtonHandler() {
            this.goToRRADetail();
        },
        /** @params { Boolean } navigateAwayOnSave */
        async saveButtonHandler( navigateAwayOnSave = false ) {
            if ( this.hasInvalidQuantityToReturn ) {
                this.shownInvalidInputModal = true;
            } else {
                await this.$store.dispatch( 'RRA/Detail/EANDetail/update' );
                if ( navigateAwayOnSave ) {
                    await this.goToRRADetail();
                }
            }
        },
        async saveAndNextButtonHandler() {
            if ( this.hasInvalidQuantityToReturn ) {
                this.shownInvalidInputModal = true;
            } else {
                await this.$store.dispatch( 'RRA/Detail/EANDetail/update' );
                if ( this.canGoToNext ) {
                    const nextItem = this.filteredRRADetailItems[ this.indexOfCurrentItem + 1 ];
                    await this.$router.push( {
                        name: 'RRAEANDetail',
                        params: {
                            ean: nextItem.isbn,
                            condition: nextItem.condition,
                            id: this.rraId,
                        },
                    } );
                } else {
                    await this.goToRRADetail();
                }
            }
        },
        /** @returns { Boolean } */
        isValidQuantityToReturn( { quantityToReturn, quantityRemaining } ) {
            return quantityToReturn <= quantityRemaining;
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/sass/mixins";

.rra-ean-detail {
    max-width: 100vw;

    .summary-section {
        margin-bottom: 1rem;

        display: grid;
        grid-auto-flow: dense;
        column-gap: 1rem;
        row-gap: 1rem;
        grid-template-columns: repeat(3, max-content 1fr);
        @include respond(tablet-landscape) {
            grid-template-columns: repeat(2, max-content 1fr);
        }
        @include respond(tablet-portrait) {
            grid-template-columns: max-content 1fr;
        }

        &__column {
            &--1:nth-child(2n+1) {
                grid-column: 1 / 2;
            }

            &--1:nth-child(2n) {
                grid-column: 2 / 3;
            }

            &--2 {
                &:nth-child(2n+1) {
                    min-width: 20rem;
                    text-align: right;
                    grid-column: 3 / 4;

                    @include respond(tablet-portrait) {
                        grid-column: 1 / 2;
                        min-width: initial;
                        text-align: left;
                    }
                }

                &:nth-child(2n) {
                    grid-column: 4 / 5;
                    @include respond(tablet-portrait) {
                        grid-column: 2 / 3;
                    }
                }
            }

            &--3 {
                &:nth-child(2n+1) {
                    grid-column: 5 / 6;
                    @include respond(tablet-landscape) {
                        grid-column: 1 / 2;
                    }
                }

                &:nth-child(2n) {
                    grid-column: 6 / 7;
                    @include respond(tablet-landscape) {
                        grid-column: 2 / 3;
                    }
                }
            }
        }

        &__label {
            font-weight: bold;
        }

        .title-designators {
            &__item {
                margin-right: .5rem;
                margin-bottom: .5rem;
            }
        }
    }

    .expansion-panels {
        margin-bottom: 7rem;
    }

    .returnable-items {
        &__qty-to-return {
            input {
                min-width: 3rem;
                width: 80%;
                max-width: 5rem;
            }
        }
    }

    .footer {
        &__save {
            margin-left: 1rem;
        }
    }

}
</style>
